document.addEventListener("turbolinks:load", function () {

    var notification = document.querySelector('.notice');

    if (notification) {
        window.setTimeout(function () {
            notification.style.display = "none";
        }, 2000);
    }

    var alert = document.querySelector('.alert');

    if (alert) {
        window.setTimeout(function () {
            alert.style.display = "none";
        }, 2000);
    }

});